<template>
  <div class="models-page">
    <h1 class="minimal-heading">Models</h1>
    <div class="models-list">
      <ModelCard
        v-for="model in filteredModels"
        :key="model.id"
        :model="model"
        class="model-card"
      />
    </div>
  </div>
</template>

<script>
import ModelCard from '@/components/ModelCard.vue';
import { models } from '@/data/modelsData.js';

export default {
  name: 'ModelsPage',
  components: {
    ModelCard,
  },
  data() {
    return {
      models,
    };
  },
  computed: {
    filteredModels() {
      const searchQuery = this.$route.query.search || "";
      return this.models.filter((model) =>
        model.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    },
  },
  mounted() {
    setTimeout(() => {
      this.$el.classList.add('loaded');
    }, 200);
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

/* Основные стили для страницы моделей */
.models-page {
  text-align: center;
  padding: 20px;
  font-family: 'Playfair Display', serif;
  transform: translateY(50px);
  opacity: 0;
  transition: transform 1s ease, opacity 1s ease;
}

.models-page.loaded {
  transform: translateY(0);
  opacity: 1;
}

/* Заголовок */
h1 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

/* Стили списка моделей */
.models-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Заголовок минимальной страницы */
.minimal-heading {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  font-size: 36px;
  letter-spacing: 1px;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 20px;
}
</style>
