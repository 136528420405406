<template>
    <div class="dashboard">
      <h1>Admin Dashboard</h1>
      <BlogManager /> <!-- Компонент для управления блогом -->
      <button @click="logout">Logout</button>
    </div>
  </template>
  
  <script>
  import { getAuth, signOut } from "firebase/auth";
  import BlogManager from "@/components/BlogManager.vue"; // компонент для управления блогом
  
  export default {
    components: {
      BlogManager,
    },
    created() {
      const auth = getAuth();
      if (!auth.currentUser) {
        this.$router.push("/admin/login"); // Перенаправление на страницу логина, если не авторизован
      }
    },
    methods: {
      logout() {
        const auth = getAuth();
        signOut(auth).then(() => {
          this.$router.push("/admin/login");
        });
      },
    },
  };
  </script>