<template>
  <div class="blog">
    <h1 class="blog-title">Blog</h1>
    <div v-if="loading" class="loading">Loading...</div>
    <div v-else>
       <div v-for="post in blogPosts" :key="post.id" class="blog-post">
        <h2 class="post-title">{{ post.title }}</h2>
        <p class="post-content">{{ post.content }}</p>
        <small class="post-author">Author: {{ post.author }}</small>
        <small class="post-date">Published: {{ post.createdAt.toDate().toLocaleString() }}</small>
        <router-link to="/admin-login" class="admin-login-button">Вход для администратора</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

export default {
  name: "BlogPage",
  data() {
    return {
      loading: true,
      blogPosts: [],
    };
  },
  async created() {
    try {
      const querySnapshot = await getDocs(collection(db, "blogPosts"));
      this.blogPosts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.blog {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.blog-title {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
}

.loading {
  font-size: 18px;
  color: #555;
}

.blog-post {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
}

.post-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
}

.post-content {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.post-author,
.post-date {
  font-size: 12px;
  color: #888;
  display: block;
}
.admin-login-button {
  margin-top: 20px;
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.admin-login-button:hover {
  background-color: #555;
}
</style>