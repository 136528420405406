export const blogs = [
  {
    id: 1,
    title: "Anna Grishina ",
    subtitle: "X Gloria Jeans",
    image: require("@/assets/blog/gloriajeans1.jpg"),
    images: [
      require("@/assets/blog/gloriajeans1.jpg"),
      require("@/assets/blog/gloriajeans2.jpg"),
      require("@/assets/blog/gloriajeans3.jpg"),
    ],
    content: "Here is the full content of the blog post...",
    modelName: "Anna Grishina",
    isSpecial: false, // Обычная карточка
  },
  {
    id: 2,
    title: "Alexandra & Anastasia ",
    subtitle: "X Numero Russia",
    image: require("@/assets/blog/numero_1.jpg"),
    images: [
      require("@/assets/blog/numero_1.jpg"),
      require("@/assets/blog/numero_2.jpg"),
      require("@/assets/blog/numero_3.jpg"),
    ],
    content: "Here is the full content of the blog post...",
    modelName: "Alexandra & Anastasia",
    isSpecial: true, // Карточка с соотношением сторон 16:9
  },
  {
    id: 3,
    title: "Dasha Grim",
    subtitle: "X MOUSSY",
    image: require("@/assets/blog/mousy1.jpg"),
    images: [
      require("@/assets/blog/mousy1.jpg"),
      require("@/assets/blog/mousy2.jpg"),
      require("@/assets/blog/mousy3.jpg"),
      require("@/assets/blog/mousy4.jpg"),
      require("@/assets/blog/mousy5.jpg"),
    ],
    content: "Here is the full content of the blog post...",
    modelName: "Dasha Grim",
    isSpecial: false,
  },
  {
    id: 4,
    title: "Katya Ponasenko",
    subtitle: "X ZNY",
    image: require("@/assets/blog/zny1.jpg"),
    images: [
      require("@/assets/blog/zny1.jpg"),
      require("@/assets/blog/zny2.jpg"),
      require("@/assets/blog/zny3.jpg"),
    ],
    content: "Here is the full content of the blog post...",
    modelName: "Katya Ponasenko",
    isSpecial: false, // Карточка с соотношением сторон 16:9
  },
  {
    id: 5,
    title: "Anna Grishina",
    subtitle: "X TBOE&Noblesse South Korea",
    image: require("@/assets/blog/TBOE&NoblesseKorea1.jpg"),
    images: [
      require("@/assets/blog/TBOE&NoblesseKorea1.jpg"),
      require("@/assets/blog/TBOE&NoblesseKorea2.jpg"),
      require("@/assets/blog/TBOE&NoblesseKorea3.jpg"),
      require("@/assets/blog/TBOE&NoblesseKorea4.jpg"),
      require("@/assets/blog/TBOE&NoblesseKorea5.jpg"),
      require("@/assets/blog/TBOE&NoblesseKorea6.jpg"),
    ],
    content: "Here is the full content of the blog post...",
    modelName: "Anna Grishina",
    isSpecial: false,
  },
  {
    id: 6,
    title: "Julia",
    subtitle: "X Nate Mazin",
    image: require("@/assets/blog/nate_1.jpg"),
    images: [
      require("@/assets/blog/nate_1.jpg"),
      require("@/assets/blog/nate_2.jpg"),
      require("@/assets/blog/nate_3.jpg"),
      require("@/assets/blog/nate_4.jpg"),
      require("@/assets/blog/nate_5.jpg"),
      require("@/assets/blog/nate_6.jpg"),
      require("@/assets/blog/nate_7.jpg"),
      require("@/assets/blog/nate_8.jpg"),
      require("@/assets/blog/nate_9.jpg"),
    ],
    content: "Here is the full content of the blog post...",
    modelName: "Julia",
    isSpecial: false, // Карточка с соотношением сторон 16:9
  },
  {
    id: 7,
    title: "Dasha Grim",
    subtitle: "X Igor Pavlov",
    image: require("@/assets/blog/pavlov_3.jpg"),
    images: [
      require("@/assets/blog/pavlov_1.jpg"),
      require("@/assets/blog/pavlov_2.jpg"),
      require("@/assets/blog/pavlov_3.jpg"),
      require("@/assets/blog/pavlov_4.jpg"),
    ],
    content: "Here is the full content of the blog post...",
    modelName: "Dasha Grim",
    isSpecial: false,
  },
];
