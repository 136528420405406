<template>
    <div class="card" @click="navigateToDetails">
      <div class="image-container">
        <img :src="blog.image" :alt="blog.title" />
      </div>
      <div class="card-content">
        <h3>{{ blog.title }}</h3>
        <p>{{ blog.subtitle }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "BlogCard",
    props: {
      blog: {
        type: Object,
        required: true,
      },
    },
    methods: {
      navigateToDetails() {
        this.$router.push(`/blog/${this.blog.id}`);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Карточка блога */
  .card {
    position: relative;
    width: 100%;
    max-width: 400px; /* Увеличенный размер карточки */
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 30px;
  }
  
  /* Изображение */
  .image-container {
    width: 100%;
    height: 0;
    padding-top: 150%; /* Соотношение сторон карточки 2:3 */
    position: relative;
    overflow: hidden;
  }
  
  .image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Заполняет карточку изображением */
    transition: transform 0.3s ease;
  }
  
  .card:hover .image-container img {
    transform: scale(1.05); /* Легкое увеличение при наведении */
  }
  
  /* Содержимое карточки */
  .card-content {
    text-align: center;
    padding: 15px 10px;
  }
  
 
.card-content h3 {
  font-family: 'Helvetica Neue', Arial, sans-serif; /* Установка шрифта */
  font-size: 16px;
  font-weight: 400; /* Вес, как в примере */
  margin: 10px 0 5px;
  color: #444;
  text-transform: uppercase;
}

.card-content p {
  font-family: 'Helvetica Neue', Arial, sans-serif; /* Шрифт, как в примере */
  font-size: 14px;
  color: #777;
  margin: 0;
  font-weight: 300; /* Легкий вес шрифта */
}
  
  /* Адаптивность */
  @media (max-width: 768px) {
    .card {
      max-width: 100%;
    }
  
    .card-content h3 {
      font-size: 14px;
    }
  
    .card-content p {
      font-size: 12px;
    }
  }
  </style>
  