<template>
  <div class="blog-details">
    <h1 class="blog-title">{{ blog.title }}</h1>
    <h2 class="blog-subtitle">{{ blog.subtitle }}</h2>

    <!-- Сетка фотографий -->
    <div class="photos-grid">
      <img v-for="(image, index) in blog.images" :key="index" :src="image" :alt="`Image ${index + 1}`" />
    </div>

    <p class="blog-content">{{ blog.content }}</p>
  </div>
</template>

<script>
import { blogs } from "@/data/blogData";

export default {
  data() {
    return {
      blog: blogs.find((b) => b.id === parseInt(this.$route.params.id)),
    };
  },
};
</script>

<style scoped>
/* Стиль для основного контейнера */
.blog-details {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
}

/* Заголовки */
.blog-title {
font-family: 'Helvetica Neue', Arial, sans-serif;
font-size: 28px;
font-weight: 400;
margin-bottom: 5px;
text-align: center;
color: #333;
}

.blog-subtitle {
font-family: 'Helvetica Neue', Arial, sans-serif;
font-size: 20px;
font-weight: 300;
margin-bottom: 20px;
text-align: center;
color: #666;
}

/* Сетка фотографий */
.photos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.photos-grid img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  transition:box-shadow 0.3s ease, transform 0.3s ease;
}

.photos-grid img:hover {
transform: scale(1.02);
box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Текстовое содержимое */
.blog-content {
font-family: 'Helvetica Neue', Arial, sans-serif;
font-size: 16px;
line-height: 1.6;
margin-top: 20px;
text-align: justify;
color: #444;
}
</style>