import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyD404SXiclIfna8qpclk82SApPwLMe0AOQ",
  authDomain: "futurefacemodels-67ee9.firebaseapp.com",
  projectId: "futurefacemodels-67ee9",
  storageBucket: "futurefacemodels-67ee9.firebasestorage.app",
  messagingSenderId: "161807788884",
  appId: "1:161807788884:web:e5b430f6315d731db9f6c1"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);


export { auth, db };


