<template>
  <nav class="navbar">
    <div class="navbar-left">
      <input
        type="text"
        v-if="!isHomePage"
        v-model="searchQuery"
        class="search-input"
        placeholder="Search"
        @input="searchModels"
      /> 
    </div>

    <div class="navbar-right" @click="toggleMenu">
      <div v-if="!menuOpen" class="menu-icon"></div>
    </div>

    <transition name="menu-slide">
      <div v-if="menuOpen" class="fullscreen-menu">
        <div class="menu-header">
          <i class="fas fa-times close-icon" @click="closeMenu"></i>
        </div>
        
        <!-- Масштабируем и уплотняем элементы меню через inline-стили -->
        <ul class="menu-list" 
            style="transform: scale(1.3); transform-origin:center center; margin:0; padding:0; line-height:0.8;">
          
          <li @click="navigateTo('/')" style="margin:0.1; padding:0.1;">
            <router-link to="/" class="nav-link" style="letter-spacing:0;">News</router-link>
          </li>
          <li @click="navigateTo('/models')" style="margin:0.1; padding:0.1;">
            <router-link to="/models" class="nav-link" style="letter-spacing:0;">Models</router-link>
          </li>
          <li style="margin:0; padding:0;">
            <a href="https://www.instagram.com/futurefacemgmt?igsh=azY0ZjY1cTUyMGds" 
               target="_blank" 
               rel="noopener" 
               class="nav-link" 
               style="letter-spacing:0;">Instagram</a>
          </li>
          <li @click="navigateTo('/be-discovered')" style="margin:0.1; padding:0.1;">
            <router-link to="/be-discovered" class="nav-link" style="letter-spacing:0;">Be Discovered</router-link>
          </li>
          <li @click="navigateTo('/contact')" style="margin:0; padding:0;">
            <router-link to="/contact" class="nav-link" style="letter-spacing:0;">Contacts</router-link>
          </li>
        </ul>
      </div>
    </transition>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
      searchQuery: "", 
    };
  },
  computed: {
    isHomePage() {
      return this.$route.path === '/';
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    navigateTo(route) {
      this.menuOpen = false;
      this.$router.push(route);
    },
    searchModels() {
      this.$router.push({ path: "/models", query: { search: this.searchQuery } });
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'MillerBannerLight';
  src: url('@/assets/fonts/Miller-Banner-Light-01.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

.nav-link {
  font-family: 'MillerBannerLight', serif;
  font-weight: 300;
  font-size: 60px; 
  letter-spacing: 0.5px;
  text-decoration: none;
  text-transform: uppercase;
  color: rgb(235, 231, 231);
  transition: color 0.5s, transform 0.5s; /* Замедляем анимацию до 0.5s */
  display: inline-block;
}

.nav-link:hover {
  color: #979393;
  transform: scale(1.1);
}

.menu-icon {
  width: 30px; 
  height: 16px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.menu-icon::before,
.menu-icon::after {
  content: "";
  width: 100%;
  height: 2px; 
  background-color: black; 
}

.menu-icon::before {
  transform: translateY(0);
}

.menu-icon::after {
  transform: translateY(1px); 
}

.navbar {
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: transparent;
  z-index: 1000;
}

.navbar-right {
  margin-right: 20px; 
}

.search-input {
  width: 100px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid rgb(5, 5, 5);
  padding-left: 10px;
}

.video-container {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  z-index: -1; 
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(10, 10, 10, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  text-align: center;
}

.menu-header {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-icon {
  font-size: 30px;
  color: rgb(245, 240, 240);
  cursor: pointer;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fullscreen-menu ul {
  list-style: none;
  padding: 0;
}

.fullscreen-menu li {
  margin: 10px 0; 
}

.menu-slide-enter-active, .menu-slide-leave-active {
  transition: transform 0.7s ease;
}

.menu-slide-enter, .menu-slide-leave-to {
  transform: translateY(-100%);
}

/* Мобильная версия */
@media (max-width: 767px) {
  .nav-link {
    font-size: 80px;
  }
  
  .fullscreen-menu .menu-list {
    transform: scale(1.0);
    line-height: 1;
    overflow-y: auto; /* Добавляем прокрутку на мобильных */
  }
  
  .fullscreen-menu li {
    margin: 5px 0;
  }
}
</style>
