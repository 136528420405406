<template>
  <div class="home">
    <!-- Логотип с анимацией -->
<!-- Логотип с анимацией -->
<div v-if="showLogo" class="logo-container">
  <img src="@/assets/1.svg" alt="Future Face Logo" class="logo-image" />
</div>


    <!-- Основной контент -->
    <div v-else class="content">
      <!-- Заголовок страницы -->
      <header class="header">
        <div class="page-title-container">
        <img src="@/assets/1.svg" alt="Future Face" class="page-title-logo" @click="openMenu" />
        </div>
      </header>

      <!-- Переключатель языка -->
      <div class="language-switcher">
        <button @click="toggleLanguage">
          <span :class="{ active: currentLanguage === 'Eng' }">Eng</span>/
          <span :class="{ active: currentLanguage === 'Rus' }">Rus</span>
        </button>
      </div>

      <!-- Поле поиска под переключателем языка -->
      <div class="search-container">
        <input
          class="search-bar"
          type="text"
          :placeholder="currentLanguage === 'Eng' ? 'Search' : 'Поиск'"
          v-model="searchQuery"
        />
      </div>

      <!-- Секция блога -->
      <div class="blog-section">
        <div class="blog-list">
          <BlogCard
            v-for="blog in filteredBlogs"
            :key="blog.id"
            :blog="blog"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogCard from "@/components/BlogCard.vue";
import { blogs } from "@/data/blogData.js";

export default {
  name: "HomePage",
  components: {
    BlogCard,
  },
  data() {
    return {
      blogs,
      showLogo: true,
      searchQuery: "",
      currentLanguage: "Eng",
    };
  },
  computed: {
    filteredBlogs() {
      return this.blogs.filter((blog) =>
        blog.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  mounted() {
    setTimeout(() => {
      this.showLogo = false;
    }, 2000);
  },
  methods: {
    openMenu() {
      this.$emit("toggle-menu");
    },
    toggleLanguage() {
      this.currentLanguage = this.currentLanguage === "Eng" ? "Rus" : "Eng";
    },
    goToInstagram() {
      window.open(
        "https://www.instagram.com/futurefacemgmt?igsh=azY0ZjY1cTUyMGds",
        "_blank"
      );
    },
  },
};
</script>

<style scoped>
/* Общий стиль */
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}

/* Логотип с анимацией */
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
  animation: fadeIn 1.5s ease-in-out, fadeOut 1.5s 1.5s ease-in-out forwards;
}

.logo-image {
  width: 700px; /* Задайте нужный размер */
  height: auto;
  animation: scaleUp 2s ease-in-out;
}

/* Контент */
.content {
  opacity: 0;
  transform: translateY(50px);
  animation: contentSlideUp 1.5s ease-in-out forwards;
  animation-delay: 1.5s;
  width: 100%;
}

/* Поле поиска */
.search-container {
  position: absolute;
  top: 80px;
  left: 30px;
  opacity: 0;
  animation: fadeInContent 1s ease-in-out forwards;
  animation-delay: 2.5s;
  z-index: 2; /* Устанавливаем z-index для правильного порядка наложения */
}

.search-bar {
  font-size: 14px;
  padding: 8px 12px;
  border: 0 solid transparent;
  outline: none;
  background: transparent;
  border-bottom: 0 solid #161616;
  transition: border-color 0.3s ease;
}

.search-bar:focus {
  border-color: #121212;
}

/* Иконка Instagram */
.page-title-container {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.instagram-icon {
  cursor: pointer;
  transition: color 0.3s ease;
  width: 30px;
  height: 30px;
  z-index: 2; /* Устанавливаем z-index для правильного порядка наложения */
}

.instagram-icon svg {
  width: 100%;
  height: 100%;
  fill: black;
}

.instagram-icon:hover svg {
  fill: gray;
}

/* Заголовок страницы */
.header {
  text-align: center;
  margin: 20px 0;
  position: relative;
  opacity: 0;
  animation: fadeInContent 1s ease-in-out forwards;
  animation-delay: 2.8s;
}

.page-title-logo {
  opacity: 0;
  display: block;
  margin: 0 auto;
  margin-top: -30px;
  width: 340px; /* подберите нужный размер */
  animation: fadeInContent 1s ease-in-out forwards;
  animation-delay: 2.8s;
  
}


/* Переключатель языка */
.language-switcher {
  position: absolute;
  top: 20px;
  left: 30px;
  opacity: 0;
  animation: fadeInContent 1s ease-in-out forwards;
  animation-delay: 2.2s;
}

.language-switcher button {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #0e0d0d;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  transition: color 0.3s ease;
}

.language-switcher button:hover {
  color: #666;
}

/* Секция блога */
.blog-section {
  padding: 30px 20px;
  width: 100%;
  background-color: #ffffff;
}

.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 8px;
  justify-items: center;
}

/* Зигзаги в карточках */
.card:nth-child(even) {
  transform: translateY(20px);
}

.card:nth-child(odd) {
  transform: translateY(-20px);
}

/* Анимации */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

@keyframes contentSlideUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInContent {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Адаптивность */
@media (max-width: 768px) {
  .blog-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .search-bar {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .blog-list {
    grid-template-columns: 1fr;
  }

  .header {
    padding: 10px 0;
  }

  .page-title {
    font-size: 28px;
  }
}
@keyframes logoGrow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2); /* или другой желаемый масштаб */
  }
}

</style>
