<template>
  <div>
    <NavbarComponent ref="navbar" />
    <router-view @toggle-menu="toggleMenu"></router-view>
  </div>
</template>

<script>
import NavbarComponent from './components/NavbarComponent.vue';

export default {
  components: { NavbarComponent },
  methods: {
    toggleMenu() {
      this.$refs.navbar.toggleMenu(); // Открываем меню через ссылку на компонент Navbar
    },
  },
};
</script>