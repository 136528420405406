<template>
  <div class="instagram-page">
    <h1>Instagram</h1>
    <a href="https://www.instagram.com/futurefacemgmt?igsh=azY0ZjY1cTUyMGds" target="_blank">
      Go to Instagram
    </a>
  </div>
</template>

<script>
export default {
  name: 'InstagramPage',
};
</script>

<style scoped>
.instagram-page {
  text-align: center;
  padding: 50px;
}

a {
  display: inline-block;
  margin-top: 20px;
  font-size: 20px;
  color: #333;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: grey;
}
</style>