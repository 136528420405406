<template>
    <div class="login-page">
      <h1>Admin Login</h1>
      <form @submit.prevent="login">
        <input type="email" v-model="email" placeholder="Email" required />
        <input type="password" v-model="password" placeholder="Password" required />
        <button type="submit">Login</button>
        <p v-if="error" class="error">{{ error }}</p>
      </form>
    </div>
  </template>
  
  <script>
  
  import { auth } from "@/firebaseConfig"; // Импортируем auth из firebaseConfig
  import { signInWithEmailAndPassword } from "firebase/auth";
  export default {
    data() {
      return {
        email: "",
        password: "",
        error: "",
      };
    },
    methods: {
      async login() {
        //const auth = getAuth(); // убедитесь, что это используется здесь
      try {
        await signInWithEmailAndPassword(auth, this.email, this.password);
        this.$router.push("/admin/dashboard");
      } catch (error) {
        this.error = error.message;
      }

      },
    },
  };
  </script>
  
  <style scoped>
  .login-page {
    max-width: 400px;
    margin: 50px auto;
    text-align: center;
  }
  
  input {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
  }
  
  button {
    padding: 10px 20px;
  }
  
  .error {
    color: red;
  }
  </style>