<template>
  <div class="model-card" @click="openModel">
    <!-- Изображение модели с анимацией при загрузке -->
    <div class="image-wrapper">
      <img 
        :src="model.mainPhoto" 
        :alt="model.name" 
        loading="lazy" 
        @load="imageLoaded = true" 
        :class="{ 'image-loaded': imageLoaded }" 
      />
    </div>
    <!-- Имя модели -->
    <h2 class="model-name">{{ model.name }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageLoaded: false, // Следит за тем, загружено ли изображение
    };
  },
  methods: {
    openModel() {
      this.$router.push(`/models/${this.model.id}`);
    },
  },
};
</script>

<style scoped>
/* Карточка модели */
.model-card {
  width: 200px;
  height: 300px;
  cursor: pointer;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.model-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Обёртка для изображения модели */
.image-wrapper {
  width: 100%;
  height: 80%;
  overflow: hidden;
  position: relative;
}

/* Изображение модели с плавной анимацией */
.model-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  opacity: 0; /* Начальная прозрачность изображения */
  transition: opacity 0.5s ease-in-out; /* Плавное появление изображения */
  transform: scale(1.1); /* Начальное увеличение для плавного приближения */
}

/* Когда изображение загружено, меняется прозрачность и масштаб */
.model-card img.image-loaded {
  opacity: 1;
  transform: scale(1); /* Возвращение к нормальному размеру */
}

.model-card img:hover {
  opacity: 0.8;
}

/* Имя модели */
.model-name {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #333;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 10px;
  transition: color 0.3s ease;
}

.model-name:hover {
  color: grey;
}
</style>
