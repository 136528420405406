<template>
  <div class="be-discovered">
    <h1 class="title">We will unlock your full potential. Submit an application and start your career with us.</h1>

    <!-- Выбор пола -->
    <div class="gender-selection">
      <label class="radio-label" :class="{ selected: gender === 'female' }">
        <input type="radio" value="female" v-model="gender" /> Female
      </label>
      <label class="radio-label" :class="{ selected: gender === 'male' }">
        <input type="radio" value="male" v-model="gender" /> Male
      </label>
      <label class="radio-label" :class="{ selected: gender === 'other' }">
        <input type="radio" value="other" v-model="gender" /> Other
      </label>
    </div>

    <!-- Форма для ввода данных -->
    <form @submit.prevent="submitApplication" class="application-form">
      <input type="text" v-model="fullName" placeholder="Full Name*" required />
      <input type="date" v-model="birth" required />

      <!-- Поле для выбора страны -->
      <select v-model="selectedCountry" class="country-select" required>
        <option value="" disabled selected>Select Country*</option>
        <option v-for="country in countries" :key="country.code" :value="country.name">
          {{ country.name }}
        </option>
      </select>

      <input type="email" v-model="email" placeholder="Email*" required />
      <input type="text" v-model="height" placeholder="Height (cm)*" required />
      <input type="text" v-model="bust" placeholder="Bust (cm)*" required />
      <input type="text" v-model="waist" placeholder="Waist (cm)*" required />
      <input type="text" v-model="hips" placeholder="Hips (cm)*" required />

      <div class="phone-section">
        <select v-model="selectedCountryForPhone" @change="updatePhoneCode">
          <option v-for="country in countries" :key="country.code" :value="country">
            {{ country.name }} ({{ country.phoneCode }})
          </option>
        </select>
        <input type="tel" v-model="phone" :placeholder="'Phone ' + selectedPhoneCode" />
      </div>

      <input type="text" v-model="instagram" placeholder="Instagram (Optional)" />

      <!-- Одно поле для загрузки фото -->
      <div class="photo-upload-section">
        <label class="upload-label">Upload Photos*</label>
        <input type="file" multiple @change="handleFileUpload" />
      </div>

      <!-- Примеры фотографий -->
      <div class="photo-examples">
        <div class="photo-example">
          <img src="@/assets/examples/full-body.jpg" alt="Full Body" />
          <p>Full Body</p>
        </div>
        <div class="photo-example">
          <img src="@/assets/examples/waist-up.jpg" alt="Waist Up" />
          <p>Waist Up</p>
        </div>
        <div class="photo-example">
          <img src="@/assets/examples/close-up.jpg" alt="Close-Up" />
          <p>Close-Up</p>
        </div>
        <div class="photo-example">
          <img src="@/assets/examples/profile.jpg" alt="Profile" />
          <p>Profile</p>
        </div>
      </div>

      <button type="submit" :disabled="!validForm">Submit</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'BeDiscoveredPage',
  data() {
    return {
      fullName: '',
      birth: '',
      email: '',
      phone: '',
      instagram: '',
      gender: '',
      height: '',
      bust: '',
      waist: '',
      hips: '',
      selectedCountry: '', // Выбранная страна
      selectedCountryForPhone: null, // Страна для телефона
      photos: [],
      countries: [
        { name: 'Russia', code: 'RU', phoneCode: '+7' },
        { name: 'United States', code: 'US', phoneCode: '+1' },
        { name: 'United Kingdom', code: 'GB', phoneCode: '+44' },
        { name: 'Germany', code: 'DE', phoneCode: '+49' },
        { name: 'France', code: 'FR', phoneCode: '+33' },
        { name: 'Spain', code: 'ES', phoneCode: '+34' },
        { name: 'Italy', code: 'IT', phoneCode: '+39' },
        { name: 'Turkey', code: 'TR', phoneCode: '+90' },
        { name: 'Japan', code: 'JP', phoneCode: '+81' },
      ],
    };
  },
  computed: {
    validForm() {
      return (
        this.fullName &&
        this.email &&
        this.phone &&
        this.height &&
        this.bust &&
        this.waist &&
        this.hips &&
        this.selectedCountry &&
        this.photos.length > 0
      );
    },
    selectedPhoneCode() {
      return this.selectedCountryForPhone ? this.selectedCountryForPhone.phoneCode : '';
    },
  },
  methods: {
    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      this.photos = files.filter(file => file.type.startsWith('image/'));
      if (files.length !== this.photos.length) {
        alert('Only image files are allowed.');
      }
    },
    submitApplication() {
      console.log('Application submitted with data:', {
        fullName: this.fullName,
        birth: this.birth,
        email: this.email,
        phone: this.phone,
        instagram: this.instagram,
        gender: this.gender,
        height: this.height,
        bust: this.bust,
        waist: this.waist,
        hips: this.hips,
        country: this.selectedCountry,
        photos: this.photos,
      });
      alert('Application submitted successfully!');
    },
  },
};
</script>

<style scoped>
.be-discovered {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.title {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  font-size: 28px;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 20px;
  color: #333;
}

.gender-selection {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.radio-label {
  cursor: pointer;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 20px;
  transition: background-color 0.3s, transform 0.2s;
  border: 1px solid #ccc;
}

.radio-label.selected {
  background-color: #333;
  color: white;
  transform: scale(1.1);
}

.radio-label input {
  display: none;
}

.application-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

.application-form input[type='text'],
.application-form input[type='email'],
.application-form input[type='date'],
.application-form input[type='tel'] {
  border: none;
  border-bottom: 2px solid #ccc;
  padding: 10px;
  outline: none;
  transition: border-color 0.3s;
}

.application-form input:focus {
  border-bottom-color: #333;
}

.country-select {
  border: none;
  border-bottom: 2px solid #ccc;
  padding: 10px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.country-select:focus {
  border-bottom-color: #333;
}

.photo-examples {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 20px 0;
}

.photo-example {
  text-align: center;
  flex: 1;
}

.photo-example img {
  width: 100px;
  height: auto;
  border-radius: 10px;
}

.photo-example p {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

.photo-upload-section {
  margin-top: 20px;
}

.upload-label {
  font-size: 16px;
  margin-bottom: 10px;
  display: block;
}

input[type='file'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 15px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #444;
}

button:disabled {
  background-color: grey;
  cursor: not-allowed;
}
</style>
